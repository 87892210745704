import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../actions/profile-completed.action';

export interface ProfileCompletedState {
  show: boolean;
  initializing: boolean;
  initialized: boolean;
  error?: any;
}

export const initialState: ProfileCompletedState = {
  show: true,
  initialized: false,
  initializing: false,
};

export const ProfileCompletedReducer = createReducer(
  initialState,
  on(pageActions.HideCompletedProfile, (state: ProfileCompletedState) => {
    return {
      ...state,
      show: false,
    };
  }),
  on(pageActions.InitCompletedProfile, (state: ProfileCompletedState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(pageActions.InitCompletedProfileSuccess, (state: ProfileCompletedState, { show }) => {
    return {
      ...state,
      show: show,
      initializing: false,
      initialized: true,
    };
  }),
  on(pageActions.ClearCompletedProfile, (state: ProfileCompletedState) => {
    return {
      ...initialState,
    };
  })
);

export const getProfileCompleted = (state: ProfileCompletedState) => state.show;
export const getProfileCompletedInitializing = (state: ProfileCompletedState) => state.initializing;
export const getProfileCompletedInitialized = (state: ProfileCompletedState) => state.initialized;
export const getProfileCompletedError = (state: ProfileCompletedState) => state.error;
