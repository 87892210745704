import { createAction, props } from '@ngrx/store';

// export const ShowCompletedProfile = createAction('[CompletedProfile] Show completed profile');
export const HideCompletedProfile = createAction('[CompletedProfile] Hide completed profile');
export const ClearCompletedProfile = createAction('[CompletedProfile] Clear completed profile');
// export const CompletedProfile = createAction('[CompletedProfile] Close completed profile');

export const InitCompletedProfile = createAction('[CompletedProfile] Initialize Settings From Local Storage');
export const InitCompletedProfileSuccess = createAction(
  '[CompletedProfile] Initialize Settings From Local Storage Success',
  props<{ show: boolean }>()
);
export const InitCompletedProfileFail = createAction(
  '[CompletedProfile] Initialize Settings From Local Storage Fail',
  props<{ error: any }>()
);
