import { environment } from 'src/environments/environment';
import * as fromSettings from './profile-completed.reducer';
import { ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';

export const featureKey = 'settings';

export interface SettingsState {
  profileCompleted: fromSettings.ProfileCompletedState;
}

export const reducers: ActionReducerMap<SettingsState> = {
  profileCompleted: fromSettings.ProfileCompletedReducer,
};

export const getSettingsState = createFeatureSelector<SettingsState>(featureKey);

export const metaReducers: MetaReducer<SettingsState>[] = !environment.production ? [] : [];
