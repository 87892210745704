import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProfileCompletedSettings from '../reducers/profile-completed.reducer';

export const getProfileCompletedSettingstate = createSelector(
  fromFeature.getSettingsState,
  (state: fromFeature.SettingsState) => state.profileCompleted
);

export const getProfileCompletedSettings = createSelector(
  getProfileCompletedSettingstate,
  fromProfileCompletedSettings.getProfileCompleted
);
export const getProfileCompletedSettingsInitialized = createSelector(
  getProfileCompletedSettingstate,
  fromProfileCompletedSettings.getProfileCompletedInitialized
);
export const getProfileCompletedSettingsInitializing = createSelector(
  getProfileCompletedSettingstate,
  fromProfileCompletedSettings.getProfileCompletedInitializing
);
export const getProfileCompletedSettingsError = createSelector(
  getProfileCompletedSettingstate,
  fromProfileCompletedSettings.getProfileCompletedError
);
