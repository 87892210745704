import { Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/profile-completed.action';
import { map, catchError, tap } from 'rxjs/operators';
import { Inject } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ProfileCompletedEffects {
  private STORAGE_KEY = 'ROVA:PROFILE_COMPLETED_SHOWN';

  constructor(
    private actions$: Actions,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<any>
  ) {}

  hideCompletedProfile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.HideCompletedProfile),
        tap(() => {
          localStorage.setItem(this.STORAGE_KEY, JSON.stringify(false));
        })
      );
    },
    { dispatch: false }
  );

  InitCompletedProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitCompletedProfile),
      map(() => {
        const storedSettings = localStorage.getItem(this.STORAGE_KEY);

        if (storedSettings) {
          return actions.InitCompletedProfileSuccess({
            show: storedSettings === 'false' ? false : true,
          });
        }
        return actions.InitCompletedProfileSuccess({ show: true });
      }),
      catchError(error => of(actions.InitCompletedProfileFail({ error })))
    );
  });

  ClearCompletedProfile$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.ClearCompletedProfile),
        tap(() => {
          localStorage.removeItem(this.STORAGE_KEY);
        })
      );
    },
    { dispatch: false }
  );
}
